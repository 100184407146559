import React from "react";
import ScrollToTopBtn from "../components/ScrollToTopBtn";

export default function AboutUs() {
  return (
    <>
    <div className="elfsight-app-78d260c3-ff2b-46e9-8d96-8353c221d8b9 aboutUs"></div>
     <ScrollToTopBtn/>
     </>
  );
}
