// 1
import room1_1 from "./images/pokoje/1/1_1.jpg";
import room1_2 from "./images/pokoje/1/1_2.jpg";
import room1_3 from "./images/pokoje/1/1_3.jpg";
import room1_4 from "./images/pokoje/1/1_4.jpg";

// 2
import room2_1 from "./images/pokoje/2/2_1.jpg";
import room2_2 from "./images/pokoje/2/2_2.jpg";
import room2_3 from "./images/pokoje/2/2_3.jpg";
import room2_4 from "./images/pokoje/2/2_4.jpg";

// 3

import room3_1 from "./images/pokoje/3/3_1.jpg";
import room3_2 from "./images/pokoje/3/3_2.jpg";
import room3_3 from "./images/pokoje/3/3_3.jpg";
import room3_4 from "./images/pokoje/3/3_4.jpg";
import room3_5 from "./images/pokoje/3/3_5.jpg";
import room3_6 from "./images/pokoje/3/3_6.jpg";

//4
import room4_1 from "./images/pokoje/4/4_1.jpg";
import room4_2 from "./images/pokoje/4/4_2.jpg";
import room4_3 from "./images/pokoje/4/4_3.jpg";
import room4_4 from "./images/pokoje/4/4_4.jpg";
import room4_5 from "./images/pokoje/4/4_5.jpg";
import room4_6 from "./images/pokoje/4/4_6.jpg";

// 5
import room5_1 from "./images/pokoje/5/5_1.jpg";
import room5_2 from "./images/pokoje/5/5_2.jpg";
import room5_3 from "./images/pokoje/5/5_3.jpg";
import room5_4 from "./images/pokoje/5/5_4.jpg";
import room5_5 from "./images/pokoje/5/5_5.jpg";

// 6
import room6_1 from "./images/pokoje/6/6_1.jpg";
import room6_2 from "./images/pokoje/6/6_2.jpg";
import room6_3 from "./images/pokoje/6/6_3.jpg";
import room6_4 from "./images/pokoje/6/6_4.jpg";
import room6_5 from "./images/pokoje/6/6_5.jpg";
import room6_6 from "./images/pokoje/6/6_6.jpg";
import room6_7 from "./images/pokoje/6/6_7.jpg";


// 7

import room7_1 from "./images/pokoje/7/7_1.jpg";
import room7_2 from "./images/pokoje/7/7_2.jpg";
import room7_3 from "./images/pokoje/7/7_3.jpg";
import room7_4 from "./images/pokoje/7/7_4.jpg";

// 8
import room8_1 from "./images/pokoje/8/8_1.jpg";
import room8_2 from "./images/pokoje/8/8_2.jpg";
import room8_3 from "./images/pokoje/8/8_3.jpg";
import room8_4 from "./images/pokoje/8/8_4.jpg";
import room8_5 from "./images/pokoje/8/8_5.jpg";
//10

import room10_1 from "./images/pokoje/10/10_1.jpg";
import room10_2 from "./images/pokoje/10/10_2.jpg";
import room10_3 from "./images/pokoje/10/10_3.jpg";
import room10_4 from "./images/pokoje/10/10_4.jpg";
import room10_5 from "./images/pokoje/10/10_5.jpg";

// 11

import room11_1 from "./images/pokoje/11/11_1.jpg";
import room11_2 from "./images/pokoje/11/11_2.jpg";
import room11_3 from "./images/pokoje/11/11_3.jpg";
import room11_4 from "./images/pokoje/11/11_4.jpg";
import room11_5 from "./images/pokoje/11/11_5.jpg";
import room11_6 from "./images/pokoje/11/11_6.jpg";
import room11_7 from "./images/pokoje/11/11_7.jpg";

// 12
import room12_1 from "./images/pokoje/12/12_1.jpg";
import room12_2 from "./images/pokoje/12/12_2.jpg";
import room12_3 from "./images/pokoje/12/12_3.jpg";
import room12_4 from "./images/pokoje/12/12_4.jpg";
import room12_5 from "./images/pokoje/12/12_5.jpg";
// 13

import room13_1 from "./images/pokoje/13/13_1.jpg";
import room13_2 from "./images/pokoje/13/13_2.jpg";
import room13_3 from "./images/pokoje/13/13_3.jpg";
import room13_4 from "./images/pokoje/13/13_4.jpg";
import room13_5 from "./images/pokoje/13/13_5.jpg";
import room13_6 from "./images/pokoje/13/13_6.jpg";

// 14
import room14_1 from "./images/pokoje/14/14_1.jpg";
import room14_2 from "./images/pokoje/14/14_2.jpg";
import room14_3 from "./images/pokoje/14/14_3.jpg";
import room14_4 from "./images/pokoje/14/14_4.jpg";
import room14_5 from "./images/pokoje/14/14_5.jpg";
import room14_6 from "./images/pokoje/14/14_6.jpg";
import room14_7 from "./images/pokoje/14/14_7.jpg";

// 15

import room15_1 from "./images/pokoje/15/15_1.jpg";
import room15_2 from "./images/pokoje/15/15_2.jpg";
import room15_3 from "./images/pokoje/15/15_3.jpg";
import room15_4 from "./images/pokoje/15/15_4.jpg";

// 16
import room16_1 from "./images/pokoje/16/16_1.jpg";
import room16_2 from "./images/pokoje/16/16_2.jpg";
import room16_3 from "./images/pokoje/16/16_3.jpg";
import room16_4 from "./images/pokoje/16/16_4.jpg";

//17

import room17_1 from "./images/pokoje/17/17_1.jpg";
import room17_2 from "./images/pokoje/17/17_2.jpg";
import room17_3 from "./images/pokoje/17/17_3.jpg";
import room17_4 from "./images/pokoje/17/17_4.jpg";
import room17_5 from "./images/pokoje/17/17_5.jpg";
import room17_6 from "./images/pokoje/17/17_6.jpg";

// 18

import room18_1 from "./images/pokoje/18/18_1.jpg";
import room18_2 from "./images/pokoje/18/18_2.jpg";
import room18_3 from "./images/pokoje/18/18_3.jpg";
import room18_4 from "./images/pokoje/18/18_4.jpg";

export default [
  {
    sys: {
      id: "1",
    },
    fields: {
      name: "Pokój nr 1",
      slug: "1",
      type: "3-osobowy",
      price: 270,
      // size: 25,
      capacity: 3,
      pets: true,
      breakfast: true,
      featured: true,
      balcony: false,
      description:
        "Pokój nr.1 wyposoażony jest w 3 1-osobowe łóżka. Na wyposażeniu pokoju szafa, komoda, szafki nocle przy łózkach. W pokoju znajduje sie prywatna łazienka(prysznic,umywalka,toaleta), TV, czajnik bezprzewodowy. Pokoj jest wykonczony w drenie. Jes bardzo ciepły. DArmowy dostęp do Wi-Fi",
      extras: [
        "Poduszki typu 'jaś'",
        "Miekkie , duże rećżniki kompielowe",
        "Wi-Fi",
        "Duze łózka z wygodnymi materacami",
        "Antyalergiczna pościel",
      ],
      images: [
        {
          fields: {
            file: {
              url: room1_1,
            },
          },
        },
        {
          fields: {
            file: {
              url: room1_2,
            },
          },
        },
        {
          fields: {
            file: {
              url: room1_3,
            },
          },
        },
        {
          fields: {
            file: {
              url: room1_4,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "2",
    },
    fields: {
      name: "Pokój nr 2",
      slug: "2",
      type: "3-osobowy",
      price: 270,
      // size: 25,
      capacity: 3,
      pets: true,
      breakfast: true,
      featured: false,
      description:
        "Pokój nr.2 wyposoażony jest w 4 1-osobowe łóżka. Na wyposażeniu pokoju szafa, komoda, szafki nocle przy łózkach. W pokoju znajduje sie prywatna łazienka(prysznic,umywalka,toaleta), TV, czajnik bezprzewodowy. Pokoj jest wykonczony w drenie. Jes bardzo ciepły. DArmowy dostęp do Wi-Fi.",
      extras: [
        "Poduszki typu 'jaś'",
        "Miekkie , duże rećżniki kompielowe",
        "Wi-Fi",
        "Duze łózka z wygodnymi materacami",
        "Antyalergiczna pościel",
      ],
      images: [
        {
          fields: {
            file: {
              url: room2_1,
            },
          },
        },
        {
          fields: {
            file: {
              url: room2_2,
            },
          },
        },
        {
          fields: {
            file: {
              url: room2_3,
            },
          },
        },
        {
          fields: {
            file: {
              url: room2_4,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "3",
    },
    fields: {
      name: "Pokój nr 3",
      slug: "3",
      type: "2-osobowy",
      price: 180,
      // size: 15,
      capacity: 2,
      pets: true,
      breakfast: true,
      featured: false,
      description:
        "Pokój nr.3 wyposoażony jest w 2 1-osobowe łóżka. Na wyposażeniu pokoju szafa, komoda, szafki nocle przy łózkach. W pokoju znajduje sie prywatna łazienka(prysznic,umywalka,toaleta), TV, czajnik bezprzewodowy. Pokoj jest wykonczony w drenie. Jes bardzo ciepły. DArmowy dostęp do Wi-Fi.",
      extras: [
        "Poduszki typu 'jaś'",
        "Miekkie , duże rećżniki kompielowe",
        "Wi-Fi",
        "Duze łózka z wygodnymi materacami",
        "Antyalergiczna pościel",
      ],
      images: [
        {
          fields: {
            file: {
              url: room3_1,
            },
          },
        },
        {
          fields: {
            file: {
              url: room3_2,
            },
          },
        },
        {
          fields: {
            file: {
              url: room3_3,
            },
          },
        },
        {
          fields: {
            file: {
              url: room3_4,
            },
          },
        },
        {
          fields: {
            file: {
              url: room3_5,
            },
          },
        },
        {
          fields: {
            file: {
              url: room3_6,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "4",
    },
    fields: {
      name: "Pokój nr 4",
      slug: "4",
      type: "4-osobowy",
      price: 360,
      // size: 30,
      capacity: 4,
      pets: true,
      breakfast: true,
      featured: false,
      description:
        "Pokój nr.4 wyposoażony jest w 4 1-osobowe łóżka. Na wyposażeniu pokoju szafa, komoda, szafki nocle przy łózkach. W pokoju znajduje sie prywatna łazienka(prysznic,umywalka,toaleta), TV, czajnik bezprzewodowy. Pokoj jest wykonczony w drenie. Jes bardzo ciepły. DArmowy dostęp do Wi-Fi.",
      extras: [
        "Poduszki typu 'jaś'",
        "Miekkie , duże rećżniki kompielowe",
        "Wi-Fi",
        "Duze łózka z wygodnymi materacami",
        "Antyalergiczna pościel",
      ],
      images: [
        {
          fields: {
            file: {
              url: room4_1,
            },
          },
        },
        {
          fields: {
            file: {
              url: room4_2,
            },
          },
        },
        {
          fields: {
            file: {
              url: room4_3,
            },
          },
        },
        {
          fields: {
            file: {
              url: room4_4,
            },
          },
        },
        {
          fields: {
            file: {
              url: room4_5,
            },
          },
        },
        {
          fields: {
            file: {
              url: room4_6,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "5",
    },
    fields: {
      name: "Pokój nr 5",
      slug: "5",
      type: "3-osobowy",
      price: 270,
      // size: 25,
      capacity: 3,
      pets: true,
      breakfast: true,
      featured: false,
      description:
        "Pokój nr.5 wyposoażony jest w 3 1-osobowe łóżka. Na wyposażeniu pokoju szafa, komoda, szafki nocle przy łózkach. W pokoju znajduje sie prywatna łazienka(prysznic,umywalka,toaleta), TV, czajnik bezprzewodowy. Pokoj jest wykonczony w drenie. Jes bardzo ciepły. DArmowy dostęp do Wi-Fi.",
      extras: [
        "Poduszki typu 'jaś'",
        "Miekkie , duże rećżniki kompielowe",
        "Wi-Fi",
        "Duze łózka z wygodnymi materacami",
        "Antyalergiczna pościel",
      ],
      images: [
        {
          fields: {
            file: {
              url: room5_1,
            },
          },
        },
        {
          fields: {
            file: {
              url: room5_2,
            },
          },
        },
        {
          fields: {
            file: {
              url: room5_3,
            },
          },
        },
        {
          fields: {
            file: {
              url: room5_4,
            },
          },
        },
        {
          fields: {
            file: {
              url: room5_5,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "6",
    },
    fields: {
      name: "Pokój nr 6",
      slug: "6",
      type: "4-osobowy",
      price: 360,
      // size: 30,
      capacity: 4,
      pets: true,
      breakfast: true,
      featured: false,
      description:
        "Pokój nr.6 wyposoażony jest w 4 1-osobowe łóżka. Na wyposażeniu pokoju szafa, komoda, szafki nocle przy łózkach. W pokoju znajduje sie prywatna łazienka(prysznic,umywalka,toaleta), TV, czajnik bezprzewodowy. Pokoj jest wykonczony w drenie. Jes bardzo ciepły. DArmowy dostęp do Wi-Fi.",
      extras: [
        "Poduszki typu 'jaś'",
        "Miekkie , duże rećżniki kompielowe",
        "Wi-Fi",
        "Duze łózka z wygodnymi materacami",
        "Antyalergiczna pościel",
      ],
      images: [
        {
          fields: {
            file: {
              url: room6_1,
            },
          },
        },
        {
          fields: {
            file: {
              url: room6_2,
            },
          },
        },
        {
          fields: {
            file: {
              url: room6_3,
            },
          },
        },
        {
          fields: {
            file: {
              url: room6_4,
            },
          },
        },
        {
          fields: {
            file: {
              url: room6_5,
            },
          },
        },
        {
          fields: {
            file: {
              url: room6_6,
            },
          },
        },
        {
          fields: {
            file: {
              url: room6_7,
            },
          },
        },
        // {
        //   fields: {
        //     file: {
        //       url: room6_8,
        //     },
        //   },
        // },
        // {
        //   fields: {
        //     file: {
        //       url: room6_9,
        //     },
        //   },
        // },
      ],
    },
  },
  {
    sys: {
      id: "7",
    },
    fields: {
      name: "Pokój nr 7",
      slug: "7",
      type: "2-osobowy",
      price: 180,
      size: 15,
      capacity: 2,
      pets: true,
      breakfast: true,
      featured: false,
      description:
        "Pokój nr 7 wyposoażony jest w 2 1-osobowe łóżka. Na wyposażeniu pokoju szafa, komoda, szafki nocle przy łózkach. W pokoju znajduje sie prywatna łazienka(prysznic,umywalka,toaleta), TV, czajnik bezprzewodowy. Pokoj jest wykonczony w drenie. Jes bardzo ciepły. DArmowy dostęp do Wi-Fi.",
      extras: [
        "Poduszki typu 'jaś'",
        "Miekkie , duże rećżniki kompielowe",
        "Wi-Fi",
        "Duze łózka z wygodnymi materacami",
        "Antyalergiczna pościel",
      ],
      images: [
        {
          fields: {
            file: {
              url: room7_1,
            },
          },
        },
        {
          fields: {
            file: {
              url: room7_2,
            },
          },
        },
        {
          fields: {
            file: {
              url: room7_3,
            },
          },
        },
        {
          fields: {
            file: {
              url: room7_4,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "8",
    },
    fields: {
      name: "Pokój nr 8",
      slug: "8",
      type: "4-osobowy",
      price: 360,
      // size: 30,
      capacity: 4,
      pets: true,
      breakfast: true,
      featured: false,
      description:
        "Pokój nr 8 wyposoażony jest w 4 1-osobowe łóżka. Na wyposażeniu pokoju szafa, komoda, szafki nocle przy łózkach. W pokoju znajduje sie prywatna łazienka(prysznic,umywalka,toaleta), TV, czajnik bezprzewodowy. Pokoj jest wykonczony w drenie. Jes bardzo ciepły. DArmowy dostęp do Wi-Fi.",
      extras: [
        "Poduszki typu 'jaś'",
        "Miekkie , duże rećżniki kompielowe",
        "Wi-Fi",
        "Duze łózka z wygodnymi materacami",
        "Antyalergiczna pościel",
      ],
      balcony: false,
      images: [
        {
          fields: {
            file: {
              url: room8_1,
            },
          },
        },
        {
          fields: {
            file: {
              url: room8_2,
            },
          },
        },
        {
          fields: {
            file: {
              url: room8_3,
            },
          },
        },
        {
          fields: {
            file: {
              url: room8_4,
            },
          },
        },
        {
          fields: {
            file: {
              url: room8_5,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "9",
    },
    fields: {
      name: "Pokój nr 9",
      slug: "9",
      type: "3-osobowy",
      price: 270,
      // size: 500,
      capacity: 3,
      pets: true,
      breakfast: false,
      featured: false,
      description:
        "Pokój nr 9 wyposoażony jest w 2 1-osobowe łóżka. Na wyposażeniu pokoju szafa, komoda, szafki nocle przy łózkach. W pokoju znajduje sie prywatna łazienka(prysznic,umywalka,toaleta), TV, czajnik bezprzewodowy. Pokoj jest wykonczony w drenie. Jes bardzo ciepły. DArmowy dostęp do Wi-Fi.",
      extras: [
        "Poduszki typu 'jaś'",
        "Miekkie , duże rećżniki kompielowe",
        "Wi-Fi",
        "Duze łózka z wygodnymi materacami",
        "Antyalergiczna pościel",
      ],
      images: [
        {
          fields: {
            file: {
              url: "",
            },
          },
        },
        {
          fields: {
            file: {
              url: "",
            },
          },
        },
        {
          fields: {
            file: {
              url: "",
            },
          },
        },
        {
          fields: {
            file: {
              url: "",
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "10",
    },
    fields: {
      name: "Pokój nr 10",
      slug: "10",
      type: "2-osobowy",
      price: 180,
      // size: 550,
      capacity: 2,
      pets: true,
      breakfast: false,
      featured: false,
      description:
        "Pokój nr 10 wyposażony jest w 1 2-osobowe łóżko(małżeńskie) ,przestrony, nowoczesnie urzadznoym pokojem z duża łązienką. Posiada TV, czajnik, WI-FI.",
      extras: [
        "Poduszki typu 'jaś'",
        "Miekkie , duże rećżniki kompielowe",
        "Wi-Fi",
        "Duze łózka z wygodnymi materacami",
        "Antyalergiczna pościel",
      ],
      images: [
        {
          fields: {
            file: {
              url: room10_1,
            },
          },
        },
        {
          fields: {
            file: {
              url: room10_2,
            },
          },
        },
        {
          fields: {
            file: {
              url: room10_3,
            },
          },
        },
        {
          fields: {
            file: {
              url: room10_4,
            },
          },
        },
        {
          fields: {
            file: {
              url: room10_5,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "11",
    },
    fields: {
      name: "Pokój nr 11",
      slug: "11",
      type: "2-osobowy",
      price: 180,
      // size: 120,
      capacity: 2,
      pets: true,
      breakfast: true,
      featured: true,
      description:
        "Pokój nr 11 wyposażony jest w 1 2-osobowe łóżko(małżeńskie) ,przestrony, nowoczesnie urzadznoym pokojem z duża łązienką. Posiada TV, czajnik, WI-FI.",
      extras: [
        "Poduszki typu 'jaś'",
        "Miekkie , duże rećżniki kompielowe",
        "Wi-Fi",
        "Duze łózka z wygodnymi materacami",
        "Antyalergiczna pościel",
      ],
      images: [
        {
          fields: {
            file: {
              url: room11_1,
            },
          },
        },
        {
          fields: {
            file: {
              url: room11_2,
            },
          },
        },
        {
          fields: {
            file: {
              url: room11_3,
            },
          },
        },
        {
          fields: {
            file: {
              url: room11_4,
            },
          },
        },
        {
          fields: {
            file: {
              url: room11_5,
            },
          },
        },
        {
          fields: {
            file: {
              url: room11_6,
            },
          },
        },
        {
          fields: {
            file: {
              url: room11_7,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "12",
    },
    fields: {
      name: "Pokój nr 12",
      slug: "12",
      type: "4-osobowy",
      price: 360,
      // size: 700,
      capacity: 4,
      pets: true,
      breakfast: true,
      featured: true,
      description:
        "Pokój nr 12 wyposażony jest w 4 1-osobowe łóżka ,przestrony, nowoczesnie urzadznoym pokojem z duża łązienką. Posiada TV, czajnik, Wi-Fi.",
      extras: [
        "Poduszki typu 'jaś'",
        "Miekkie , duże rećżniki kompielowe",
        "Wi-Fi",
        "Duze łózka z wygodnymi materacami",
        "Antyalergiczna pościel",
      ],
      images: [
        {
          fields: {
            file: {
              url: room12_1,
            },
          },
        },
        {
          fields: {
            file: {
              url: room12_2,
            },
          },
        },
        {
          fields: {
            file: {
              url: room12_3,
            },
          },
        },
        {
          fields: {
            file: {
              url: room12_4,
            },
          },
        },
        {
          fields: {
            file: {
              url: room12_5,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "13",
    },
    fields: {
      name: "Pokój nr 13",
      slug: "13",
      type: "3-osobowy",
      price: 270,
      // size: 1000,
      capacity: 2,
      pets: true,
      breakfast: true,
      featured: false,
      description:
        "Pokój nr 13 wyposażony jest w 2 1-osobowe łóżko(małżeńskie) ,przestrony, nowoczesnie urzadznoym pokojem z duża łązienką. Posiada TV, czajnik, Wi-Fi.",
      extras: [
        "Poduszki typu 'jaś'",
        "Miekkie , duże rećżniki kompielowe",
        "Wi-Fi",
        "Duze łózka z wygodnymi materacami",
        "Antyalergiczna pościel",
      ],
      images: [
        {
          fields: {
            file: {
              url: room13_1,
            },
          },
        },
        {
          fields: {
            file: {
              url: room13_2,
            },
          },
        },
        {
          fields: {
            file: {
              url: room13_3,
            },
          },
        },
        {
          fields: {
            file: {
              url: room13_4,
            },
          },
        },
        {
          fields: {
            file: {
              url: room13_5,
            },
          },
        },
        {
          fields: {
            file: {
              url: room13_6,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "14",
    },
    fields: {
      name: "Pokój nr 14",
      slug: "14",
      type: "2-osobowy",
      price: 180,
      // size: 1000,
      capacity: 2,
      pets: true,
      breakfast: true,
      featured: false,
      description:
        "Pokój nr 14 wyposażony jest w 1 2-osobowe łóżko(małżeńskie) ,przestrony, nowoczesnie urzadznoym pokojem z duża łązienką. Posiada TV, czajnik, Wi-Fi.",
      extras: [
        "Poduszki typu 'jaś'",
        "Miekkie , duże rećżniki kompielowe",
        "Wi-Fi",
        "Duze łózka z wygodnymi materacami",
        "Antyalergiczna pościel",
      ],
      images: [
        {
          fields: {
            file: {
              url: room14_1,
            },
          },
        },
        {
          fields: {
            file: {
              url: room14_2,
            },
          },
        },
        {
          fields: {
            file: {
              url: room14_3,
            },
          },
        },
        {
          fields: {
            file: {
              url: room14_4,
            },
          },
        },
        {
          fields: {
            file: {
              url: room14_5,
            },
          },
        },
        {
          fields: {
            file: {
              url: room14_6,
            },
          },
        },
        {
          fields: {
            file: {
              url: room14_7,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "15",
    },
    fields: {
      name: "Pokój nr 15",
      slug: "15",
      type: "2-osobowy",
      price: 180,
      // size: 1000,
      capacity: 2,
      pets: true,
      breakfast: true,
      featured: false,
      description:
        "Pokój nr 15 wyposażony jest w 1 2-osobowe łóżko(małżeńskie) ,przestrony, nowoczesnie urzadznoym pokojem z duża łązienką. Posiada TV, czajnik, Wi-Fi.",
      extras: [
        "Poduszki typu 'jaś'",
        "Miekkie , duże rećżniki kompielowe",
        "Wi-Fi",
        "Duze łózka z wygodnymi materacami",
        "Antyalergiczna pościel",
      ],
      images: [
        {
          fields: {
            file: {
              url: room15_1,
            },
          },
        },
        {
          fields: {
            file: {
              url: room15_2,
            },
          },
        },
        {
          fields: {
            file: {
              url: room15_3,
            },
          },
        },
        {
          fields: {
            file: {
              url: room15_4,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "16",
    },
    fields: {
      name: "Pokój nr 16",
      slug: "16",
      type: "2-osobowy",
      price: 180,
      // size: 1000,
      capacity: 2,
      pets: true,
      breakfast: true,
      featured: false,
      description:
        "Pokój nr 16 wyposażony jest w 1 2-osobowe łóżko(małżeńskie) ,przestrony, nowoczesnie urzadznoym pokojem z duża łązienką. Posiada TV, czajnik, Wi-Fi.",
      extras: [
        "Poduszki typu 'jaś'",
        "Miekkie , duże rećżniki kompielowe",
        "Wi-Fi",
        "Duze łózka z wygodnymi materacami",
        "Antyalergiczna pościel",
      ],
      images: [
        {
          fields: {
            file: {
              url: room16_1,
            },
          },
        },
        {
          fields: {
            file: {
              url: room16_2,
            },
          },
        },
        {
          fields: {
            file: {
              url: room16_3,
            },
          },
        },
        {
          fields: {
            file: {
              url: room16_4,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "17",
    },
    fields: {
      name: "Pokój nr 17",
      slug: "17",
      type: "2-osobowy",
      price: 180,
      // size: 1000,
      capacity: 2,
      pets: true,
      breakfast: true,
      featured: false,
      description:
        "Pokój nr 17 wyposażony jest w 1 2-osobowe łóżko(małżeńskie) ,przestrony, nowoczesnie urzadznoym pokojem z duża łązienką. Posiada TV, czajnik, Wi-Fi.",
      extras: [
        "Poduszki typu 'jaś'",
        "Miekkie , duże rećżniki kompielowe",
        "Wi-Fi",
        "Duze łózka z wygodnymi materacami",
        "Antyalergiczna pościel",
      ],
      images: [
        {
          fields: {
            file: {
              url: room17_1,
            },
          },
        },
        {
          fields: {
            file: {
              url: room17_2,
            },
          },
        },
        {
          fields: {
            file: {
              url: room17_3,
            },
          },
        },
        {
          fields: {
            file: {
              url: room17_4,
            },
          },
        },
        {
          fields: {
            file: {
              url: room17_5,
            },
          },
        },
        {
          fields: {
            file: {
              url: room17_6,
            },
          },
        },
      ],
    },
  },
  {
    sys: {
      id: "18",
    },
    fields: {
      name: "Pokój nr 18",
      slug: "18",
      type: "1-osobowy",
      price: 90,
      // size: 1000,
      capacity: 1,
      pets: true,
      breakfast: true,
      featured: true,
      description:
        "Pokój nr 18 wyposażony jest w 1 1-osobowe łóżko(małżeńskie) ,przestrony, nowoczesnie urzadznoym pokojem z duża łązienką. Posiada TV, czajnik, Wi-Fi.",
      extras: [
        "Poduszki typu 'jaś'",
        "Miekkie , duże rećżniki kompielowe",
        "Wi-Fi",
        "Duze łózka z wygodnymi materacami",
        "Antyalergiczna pościel",
      ],
      images: [
        {
          fields: {
            file: {
              url: room18_1,
            },
          },
        },
        {
          fields: {
            file: {
              url: room18_2,
            },
          },
        },
        {
          fields: {
            file: {
              url: room18_3,
            },
          },
        },
        {
          fields: {
            file: {
              url: room18_4,
            },
          },
        },
      ],
    },
  },
];
